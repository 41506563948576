import { ref as dbRef, child as dbChild, get as dbGet } from "firebase/database";

const userDataCache = ref(null);

export const useUserData = async (reset = false) => {
  if (reset) {
    console.log("UserData:Reset");
    userDataCache.value = null;
    return;
  }

  const user = await getCurrentUser();

  if (!user) return;

  if (userDataCache?.value) {
    // Force Reset if id doesn't match cache
    if (userDataCache?.value?.uid !== user?.uid) {
      console.log("UserData:Reset");
      userDataCache.value = null;
      return;
    }

    console.log("UserData:Cache");
    return userDataCache?.value;
  }

  const fetchUserData = async (userId) => {
    console.log("UserData:Fetch");

    const db = useDatabase();
    const dbRefUser = await dbChild(dbRef(db, "users"), `${userId}`);
    const data = (await dbGet(dbRefUser)).val();

    userDataCache.value = data;

    return data;
  };

  const { data, error } = await useAsyncData(`user-data-${user.uid}`, async () => await fetchUserData(user.uid));

  if (error?.value) {
    console.log("error");
    throw error;
  }

  return data.value;
};
